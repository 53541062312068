import { ServiceAccount } from 'firebase-admin';
import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();

interface ServerEnv {
  adminPassword: string;
  firebaseCredentials: ServiceAccount;
  adminKey: string;
}

export const serverEnv: ServerEnv = serverRuntimeConfig;

export const isProd = process.env.NODE_ENV === 'production';
