import { createQueryString } from '@florencecard-lib/query-string';
import { LetterTheme } from '@florencecard-shared/letter';
import { LetterData } from '@florencecard-shared/letter-builder';
import { EnterpriseUser } from '@florencecard-shared/user';
import axios from 'axios';
import { Observable, of, pipe, throwError } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mapTo } from 'rxjs/operators';
import { ApiErrorCodes, queryApiErrorCode } from '~/core/api/errors';
import {
  AcceptEnterpriseUserSignUpPayload,
  AuthPasswordRequestPayload,
  AuthUserRequestPayload,
  DayPaymentsStatsRequestPayload,
  DayPaymentsStatsResponsePayload,
  LetterBuildRequestPayload,
  LetterExpireRequestPayload,
  LetterGetByOrderIdRequestPayload,
  LettersListRequestPayload,
  MonthPaymentsStatsRequestPayload,
  MonthPaymentsStatsResponsePayload,
  PaymentRefundRequestPayload,
  PreSignedUploadFileRequestPayload,
  PreSignedUploadFileResponsePayload,
  UploadFileRequestPayload,
  WithoutToken,
  WithToken,
} from '~/core/api/payloads';
import { isProd } from '~/env';
import { Letter, LetterWithPayment } from '~/models/letter';
import { Paginated } from '~/models/pagination';
import { User } from '~/models/user';

const baseAdminApiUrl = '/api';
const baseServerApiUrl = isProd ? 'https://api.florencecard.me' : 'http://localhost:5000';

const createAuthorizationHeader = (token: string) => ({
  Authorization: `Bearer ${token}`,
});
const getResponse = pipe(map((result: AjaxResponse) => result.response));

export function checkPassword(payload: AuthPasswordRequestPayload) {
  return ajax
    .post(`${baseAdminApiUrl}/auth/password`, payload, {
      'Content-Type': 'application/json',
    })
    .pipe(
      mapTo(true),
      catchError((error) => {
        if (queryApiErrorCode(error) === ApiErrorCodes.인증실패) {
          return of(false);
        }

        return throwError(error);
      }),
    );
}

export function getUser({ token }: AuthUserRequestPayload): Observable<User> {
  return ajax
    .get(`${baseAdminApiUrl}/auth/user`, createAuthorizationHeader(token))
    .pipe(getResponse);
}

export function revertCancellationRequest(payload: LetterExpireRequestPayload): Observable<true> {
  const { token, letterUrl } = payload;

  return ajax
    .post(
      `${baseAdminApiUrl}/letters/revertCancellationRequest`,
      { letterUrl },
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    )
    .pipe(getResponse);
}

export function expireLetter(payload: LetterExpireRequestPayload): Observable<true> {
  const { token, letterUrl } = payload;

  return ajax
    .post(
      `${baseAdminApiUrl}/letters/expiry`,
      { letterUrl },
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    )
    .pipe(getResponse);
}

export function buildLetter(payload: LetterBuildRequestPayload): Observable<true> {
  const { token, ...body } = payload;

  return ajax
    .post(`${baseAdminApiUrl}/letters/build`, body, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    })
    .pipe(getResponse);
}

export function refundPayment(payload: PaymentRefundRequestPayload): Observable<true> {
  const { token, ...data } = payload;

  return ajax
    .post(`${baseAdminApiUrl}/payments/refund`, data, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    })
    .pipe(getResponse);
}

export function fetchDayPaymentsStats(
  payload: DayPaymentsStatsRequestPayload,
): Observable<DayPaymentsStatsResponsePayload> {
  const { token, ...others } = payload;
  const queryParams = createQueryString(others as WithoutToken<DayPaymentsStatsRequestPayload>);

  return ajax
    .get(`${baseAdminApiUrl}/payments/dayStats${queryParams}`, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    })
    .pipe(getResponse);
}

export function fetchMonthPaymentsStats(
  payload: MonthPaymentsStatsRequestPayload,
): Observable<MonthPaymentsStatsResponsePayload> {
  const { token, ...others } = payload;
  const queryParams = createQueryString(others as WithoutToken<MonthPaymentsStatsRequestPayload>);

  return ajax
    .get(`${baseAdminApiUrl}/payments/monthStats${queryParams}`, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    })
    .pipe(getResponse);
}

export function listLetters(
  payload: LettersListRequestPayload,
): Observable<Paginated<LetterWithPayment>> {
  const { token, ...others } = payload;
  const queryParams = createQueryString(others as WithoutToken<LettersListRequestPayload>);

  return ajax
    .get(`${baseAdminApiUrl}/letters/list${queryParams}`, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    })
    .pipe(getResponse);
}

export function getLetterByOrderId(payload: LetterGetByOrderIdRequestPayload): Observable<Letter> {
  const { token, orderId } = payload;

  return ajax
    .get(`${baseAdminApiUrl}/letters/get-by-orderid?orderId=${orderId}`, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    })
    .pipe(getResponse);
}

export async function fetchPreSignedUploadFile(payload: PreSignedUploadFileRequestPayload) {
  const { data } = await axios.post<PreSignedUploadFileResponsePayload>(
    `${baseServerApiUrl}/common.createPresignedUploadImage`,
    payload,
  );

  return data;
}

export async function uploadFile(payload: UploadFileRequestPayload) {
  const { file, fileType, preSignedUrl, preSignedFields } = payload;

  const formData = new FormData();
  formData.append('Content-Type', fileType);
  Object.entries(preSignedFields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('file', file); // must be the last one

  await axios.post<void>(preSignedUrl, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function previewLetter(payload: { theme: LetterTheme; data: LetterData }) {
  const {
    data: { html },
  } = await axios.post<{ html: string }>(`${baseServerApiUrl}/letters.preview`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return html;
}

export async function listEnterpriseUsers({ token }: { token: string }) {
  const { data } = await axios.get<EnterpriseUser[]>(`${baseAdminApiUrl}/users/enterprise/list`, {
    headers: {
      ...createAuthorizationHeader(token),
    },
  });

  return data;
}

export async function acceptEnterpriseUserSignUp({
  token,
  ...payload
}: WithToken<AcceptEnterpriseUserSignUpPayload>) {
  await axios.post(`${baseAdminApiUrl}/users/enterprise/accept-signup`, payload, {
    headers: {
      ...createAuthorizationHeader(token),
    },
  });
}
