import { AjaxError } from 'rxjs/ajax';

export enum ApiErrorCodes {
  인증실패 = 'auth-failed',
  접근권한_없음 = 'auth-forbidden',
  없는경로 = 'not-found-path',
  없는버전 = 'version-not-exists',
  청첩장_주소가_유효하지_않음 = 'invalid-letter-url',
  이미_청첩장이_만료됨 = 'letter-already-expired',
  청첩장을_찾을_수_없음 = 'letter-not-found',
}

export interface ApiError {
  code: string;
  message?: string;
}

export function isApiError(error: unknown): error is ApiError {
  return (
    error != null && typeof error === 'object' && typeof (error as ApiError)?.code === 'string'
  );
}

export function isAjaxError(error: unknown): error is AjaxError {
  return error != null && typeof error === 'object' && error instanceof AjaxError;
}

export function queryApiErrorCode(error: unknown) {
  if (isAjaxError(error)) {
    return isApiError(error.response) ? error.response.code : undefined;
  }

  if (isApiError(error)) {
    return error.code;
  }

  return undefined;
}

export function queryApiErrorMessage(
  error: unknown,
  fallback = '알 수 없는 오류가 발생하였습니다.',
) {
  if (isAjaxError(error)) {
    return (error.response?.message as string) ?? fallback;
  }

  if (isApiError(error)) {
    return error.message ?? fallback;
  }

  return fallback;
}
